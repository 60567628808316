export interface getTranslationsProps {
  translationNamespaces?: string[];
  translationContext?: string;
  userLocale?: string;
}

export const getTranslations = async ({
  translationNamespaces,
  translationContext,
  userLocale,
}: getTranslationsProps) => {
  const translationArray = ["labels", "common", "feedback"];

  if (translationNamespaces && translationNamespaces.length > 0) {
    translationArray.push(...translationNamespaces);
  }

  const dictionaryUrl = process.env.HERMES_URL + "/getdictionary";
  const body = {
    context: translationContext ? translationContext : undefined,
    language: userLocale ? userLocale : "nl",
    namespace: translationArray,
  };

  const dictionaryResponse = await fetch(dictionaryUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      credentials: "include",
      Authorization: "Bearer " + process.env.HERMES_TOKEN,
    },
    body: JSON.stringify(body),
  });

  const messages = await dictionaryResponse.json();
  return messages;
};
