import { createHttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import pino from "pino";

export const httpLink = (
  token: string,
  uri: string,
  headers?: {
    [key: string]: string;
  }
) =>
  createHttpLink({
    uri: uri,
    credentials: "include",
    headers: {
      ...headers,
      authorization: "bearer " + token,
    },
  });

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (typeof window !== "undefined") {
        const logger = pino({ browser: { asObject: true } });
        logger.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      } else {
        const logger = pino();
        logger.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      }
    });
  }
  if (networkError) {
    if (typeof window !== "undefined") {
      const logger = pino({ browser: { asObject: true } });
      logger.error(`[Network error]: ${networkError}`);
    } else {
      const logger = pino();
      logger.error(`[Network error]: ${networkError}`);
    }
  }
});
