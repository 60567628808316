/* eslint-disable func-style */
import * as React from "react";
import Typography from "@mui/material/Typography";
import Modal, { ModalProps } from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useWindowDimensions } from "@patron/utils/hooks";
import { useTheme } from "@patron/utils/theme";

/* eslint-disable-next-line */
export type CustomModalProps  = {
  modalTitle?: string;
  openModal: boolean;
  closeHandler: (event?: any, reason?: string) => void;
  editMode?: string;
  minWidth?: number | string;
  maxWidth?: number | string;
  disableEscapeKey?: boolean;
  editEnabled?: boolean;
  minHeight?: number | string;
  disableDivider?: boolean;
} & Omit<ModalProps, "open" | "onClose">;

export function CustomModal(props: React.PropsWithChildren<CustomModalProps>) {
  const {
    openModal,
    closeHandler,
    modalTitle,
    editMode,
    minWidth,
    maxWidth,
    minHeight,
    editEnabled,
    disableEscapeKey,
    children,
    disableDivider,
    ...restProps
  } = props;
  const handleClose = (event: any, reason: string) => {
    closeHandler(event, reason);
  };
  const { height } = useWindowDimensions();
  const theme = useTheme();
  const handleClosePressed = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    closeHandler();
  };
  return (
    <Modal
      open={openModal}
      disableEscapeKeyDown={disableEscapeKey}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...restProps}
    >
      <Box
        id="CustomModal2"
        sx={{
          position: "absolute" as "absolute",
          top: "40px",
          left: "50%",
          transform: "translate(-50%, 0)",
          bgcolor: theme.appColors.background,
          boxShadow: 24,
          borderRadius: 4,
          padding: 8,
        }}
        onClick={(event) => event.stopPropagation()}
        minWidth={minWidth ? minWidth : "inherit"}
        maxWidth={maxWidth ? maxWidth : "inherit"}
        borderRadius={1}
        minHeight={minHeight ? minHeight : "inherit"}
      >
        <Stack width="100%" direction="row" padding={2}>
          <Stack width="100%" direction="row">
            {modalTitle ? (
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h4"
                width={"100%"}
              >
                {modalTitle}
              </Typography>
            ) : (
              <Box
                sx={{
                  flex: 1,
                }}
              />
            )}
            {editEnabled && (
              <Typography
                variant="body1"
                component="h4"
                alignSelf="center"
                ml={2}
                color={theme.appColors.error}
              >
                {editMode}
              </Typography>
            )}

            <Stack width="50%" direction="row-reverse" alignItems="center">
              <IconButton
                onClick={(event) => handleClosePressed(event)}
                sx={{
                  display: "flex",
                  width: "24px",
                  height: "24px",
                }}
              >
                <CloseIcon sx={{ color: "action.active", fontSize: 24 }} />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>

        {!disableDivider && <Divider />}
        <Stack
          id="CustomModalInnerStack"
          width="100%"
          maxHeight={height ? height - 120 : "100%"}
          padding={2}
        >
          {children}
        </Stack>
      </Box>
    </Modal>
  );
}

export default CustomModal;
