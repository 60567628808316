"use client";

import { ThemeProvider } from "@mui/material/styles";
import { getTheme } from "@patron/utils/theme";
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { SnackbarProvider } from "notistack";

LicenseInfo.setLicenseKey(
  "39b20c1effd4c09bb8a75446913ddebaTz04MDMzOSxFPTE3MzM4NDI0OTEwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export const ClientProviders = ({ children }: { children?: any }) => (
  <ThemeProvider theme={getTheme()}>
    <CssBaseline />
    <SnackbarProvider
      autoHideDuration={3000}
      maxSnack={3}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {children}
    </SnackbarProvider>
  </ThemeProvider>
);
