/* eslint-disable func-style */
import { useState, useEffect } from "react";

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      // Set window width/height to state
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Add event listener
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return (): void => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};
