import * as React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { DialogContent, Stack } from "@mui/material";
import { SxProps, Theme } from "@mui/material";

type CustomAlertDialogProps = DialogProps & {
  open: boolean;
  children?: React.ReactNode;
  heading: string;
  useFullWidth?: boolean;
  leftButtonHeading?: string;
  rightButtonHeading: string;
  handleClosePressed: () => void;
  handleCancel: () => void;
  sx?: SxProps<Theme>;
  textCentre?: boolean;
};

export const CustomDialog = (props: CustomAlertDialogProps) => {
  const {
    open,
    heading,
    handleClosePressed,
    handleCancel,
    children,
    leftButtonHeading,
    rightButtonHeading,
    sx,
    textCentre,
    ...others
  } = props;
  const [openDialog, setOpenDialog] = React.useState(open);

  React.useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleClose = () => {
    handleClosePressed();
  };

  const handleCancelPressed = () => {
    handleCancel();
  };

  return (
    <Stack id="CustomDialog" sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Dialog
        open={openDialog}
        onClose={handleCancelPressed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...others}
      >
        <DialogTitle
          textAlign={textCentre ? "center" : "left"}
          id="alert-dialog-title"
        >
          {heading}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {leftButtonHeading && (
            <Button color="primary" onClick={handleCancelPressed}>
              {leftButtonHeading}
            </Button>
          )}
          <Button color="primary" variant="contained" onClick={handleClose}>
            {rightButtonHeading}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
export default CustomDialog;
