/* eslint-disable func-style */
import { useMemo } from "react";
import {
  ApolloClient,
  NormalizedCacheObject,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";

import { errorLink, httpLink } from "@patron/utils/apollo";

let apolloClient: any;
const JWT_COOKIE_NAME = process.env.JWT_COOKIE_NAME || "jwt";
const GRAPHQL_URL = process.env.GRAPHQL_URL || "/query";

export interface ApolloClientContext {
  req?: any;
}

export function initializeApollo(initialState: any = null) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(initialState: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}

const createApolloClient = () =>
  new ApolloClient({
    ssrMode: typeof window === "undefined",
    uri: "/query",
    cache: new InMemoryCache(),
    credentials: "include",
    link: from([
      errorLink,
      createHttpLink({
        uri: GRAPHQL_URL,
        credentials: "include",
      }),
    ]),
  });

// used by SSR call only.
export const getApolloClient = (
  ctx?: ApolloClientContext,
  initialState?: NormalizedCacheObject
) => {
  let token;
  if (ctx && ctx.req) {
    const { req } = ctx;
    if (JWT_COOKIE_NAME) {
      token = req.cookies[JWT_COOKIE_NAME];
    } else {
      token = req.cookies["jwt"];
    }
  }

  const cache = new InMemoryCache().restore(initialState || {});
  return new ApolloClient({
    uri: GRAPHQL_URL,
    cache,
    headers: {
      authorization: "bearer " + token,
    },
    link: from([errorLink, httpLink(token, GRAPHQL_URL)]),
  });
};
